import { gql } from 'graphql-request'

export const GET_LASTEST_UPDATED_BOOKS = gql`
  query GetLatestUpdatedBooks(
    $bookTypes: [BookType!]!
    $page: Int
    $limitPerPage: Int
  ) {
    getLatestUpdatedBooks(
      bookTypes: $bookTypes
      page: $page
      limitPerPage: $limitPerPage
    ) {
      data {
        id
        writer
        coverImgPath
        coverResizeImgPath
        cover
        title
        publishedChapterCount
        viewCount
        isLiked
        likeCount
        commentCount
        isEnded
        hasSalePromotions
        buyAllChaptersPromotion {
          startDate
          endDate
          discountType
          discountPercentage
        }
        readFreePromotion {
          startedAt
          endedAt
        }
        bookBadges {
          badgeCategoryId
          badgeImageUrl
        }
        category {
          id
          name
        }
        penName {
          firstPenName
          user {
            id
            displayName
            profilePageSlug
            avatarImgPath
            writer {
              affiliation
              status
            }
          }
        }
        tags {
          id
          name
        }
        bookType
        publishedAt
        writingType
        isTranslated
        isEpub
        isPdf
        rating {
          id
          name
        }
      }
      total
      page
    }
  }
`
